<template>
  <b-form-input
    v-if="disabled"
    disabled
    :value="utcToLocal(value)"
  />
  <flat-pickr
    v-else
    class="form-control"
    :value="utcToLocal(value)"
    :config="config"
    :class="state ? 'flatpickr-input': 'my-invalid-pickr'"
    @input="$emit('input', localToUtc($event))"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormInput,
} from 'bootstrap-vue'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'

export default defineComponent({
  components: {
    BFormInput,
    flatPickr,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: null,
    },
    config: {
      type: Object,
      default: () => ({ dateFormat: 'Y-m-d' }),
    },
    state: {
      type: Boolean,
      default: true,
    },
    endTime: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const utcToLocal = value => {
      const momentValue = moment(value)
      if (props.config.dateFormat === 'Y-m-d') {
        return momentValue.isValid() ? momentValue.format('Y-MM-DD') : ''
      }
      return momentValue.isValid() ? momentValue.format('YYYY-MM-DD HH:mm:ss') : ''
    }
    const localToUtc = value => {
      const momentValue = moment(value)
      if (props.endTime) {
        return momentValue.isValid() ? momentValue.endOf('date').utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]') : null
      }
      return momentValue.isValid() ? momentValue.utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]') : null
    }
    return {
      utcToLocal,
      localToUtc,
    }
  },
})
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style scoped>
.my-invalid-pickr {
  border-color: var(--danger);
}
</style>
