<template>
  <TagbyActionButtonV2
    :isValid="true"
    text="TEST"
    @click="test"
  />
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import TagbyActionButtonV2 from "@/components/TagbyActionButtonV2.vue"
import state from "../../state"

export default defineComponent({
  components: {
    TagbyActionButtonV2,
  },
  setup() {
    const test = () => {
      console.log(state)
    }
    return {
      test,
    }
  },
})
</script>
