export default () => ({
  hasLoadError: false,
  adminPermission: null,
  inf_idx: null,
  gender_weight: 1,
  age_weight: 1,
  mission_type_weight: 1,
  item_category_weight: 1,
  reward_weight: 1,
  is_precheck_weight: 1,
  isSaving: false,
  campaignList: [],
  errorReasonModal: {
    isVisible: false,
    errorReason: null,
  },
})
