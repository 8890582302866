<template>
  <BCol
    sm="12"
    md="6"
    class="d-flex align-items-stretch"
    style="padding: 0px 10px 0px 10px; margin-bottom: 10px"
  >
    <BButton
      :variant="variant"
      :block="block"
      :disabled="isSaving || !isValid"
      @click="$emit('click')"
    >
      <BSpinner
        v-if="isSaving"
        small
      />
      {{ text }}
    </BButton>
  </BCol>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCol,
  BButton,
  BSpinner,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BCol,
    BButton,
    BSpinner,
  },
  props: {
    isSaving: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    text: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: null,
    },
    block: {
      type: Boolean,
      default: true,
    },
  },
  setup() {

  },
})
</script>
