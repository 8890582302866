<template>
  <TagbyDangerModal
    :visible="state.errorReasonModal.isVisible"
    title="Error reason"
    ok-only
    okTitle="ok"
    @hidden="turnOffModal"
  >
    <Prism
      :code="state.errorReasonModal.errorReason"
      style="max-height: 300px"
    />
  </TagbyDangerModal>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import "prismjs"
import "prismjs/themes/prism-tomorrow.css"
import Prism from "vue-prism-component"
import TagbyDangerModal from "@/components/TagbyDangerModal.vue"
import state from "../../state"
import useErrorReasonModal from "../../hooks/useErrorReasonModal"

export default defineComponent({
  components: {
    TagbyDangerModal,
    Prism,
  },
  setup() {
    const {
      turnOffModal,
    } = useErrorReasonModal()

    return {
      state,
      turnOffModal,
    }
  },
})
</script>
