<template>
  <TagbyDetailLayout :hasLoadError="state.hasLoadError">
    <template #content>
      <TagbyTabs :tabList="tabList">
        <template #tab(default)>
          <InfoCard />
          <ResultTable />
        </template>
      </TagbyTabs>
    </template>

    <template #side>
      <ActionCard />
    </template>

    <template #hidden>
      <Modals />
    </template>
  </TagbyDetailLayout>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import { getRouterIdx } from "@/utils/router"
import TagbyTabs from "@/components/TagbyTabs.vue"
import TagbyDetailLayout from "@/components/TagbyDetailLayout.vue"
import ActionCard from "./components/ActionCard/Main.vue"
import InfoCard from "./components/InfoCard"
import ResultTable from "./components/ResultTable"
import Modals from "./components/Modals"
import state from "./state"
import getters from "./getters"
import useState from "./hooks/useState"

export default defineComponent({
  components: {
    TagbyDetailLayout,
    TagbyTabs,
    ActionCard,
    InfoCard,
    ResultTable,
    Modals,
  },
  setup() {
    const { resetState } = useState()
    resetState()

    state.idx = getRouterIdx()

    const tabList = [
      { key: "default", title: "기본" },
    ]

    return {
      tabList,
      state,
      getters,
    }
  },
})
</script>
