<template>
  <TagbyActionButtonV2
    :isSaving="state.isSaving"
    :isValid="true"
    variant="outline-primary"
    text="캠페인 추천"
    @click="recommend"
  />
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import TagbyActionButtonV2 from "@/components/TagbyActionButtonV2.vue"
import state from "../../state"
import getters from "../../getters"
import useRecommend from "../../hooks/useRecommend"

export default defineComponent({
  components: {
    TagbyActionButtonV2,
  },
  setup() {
    const {
      recommend,
    } = useRecommend()
    return {
      state,
      getters,
      recommend,
    }
  },
})
</script>
