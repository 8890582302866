import {
  computed,
  reactive,
} from "@vue/composition-api"

import state from "./state"

const isCreatingMode = computed(() => !state.idx)

export default reactive({
  isCreatingMode,
})
