<template>
  <TagbyActionCardV2>
    <RecommendButton />
  </TagbyActionCardV2>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import TagbyActionCardV2 from "@/components/TagbyActionCardV2.vue"
import RecommendButton from "./RecommendButton.vue"
import TestButton from "./TestButton.vue"

export default defineComponent({
  components: {
    TagbyActionCardV2,
    RecommendButton,
    TestButton,
  },
  setup() {

  },
})
</script>
