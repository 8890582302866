import state from "../state"
import api from "../api"
import useErrorReasonModal from "./useErrorReasonModal"

export default () => {
  const {
    turnOnModal: turnOnErrorModal,
  } = useErrorReasonModal()
  const recommend = () => {
    state.isSaving = true
    state.campaignList = []
    return api.recommend({
      inf_idx: state.inf_idx,
      gender_weight: state.gender_weight,
      age_weight: state.age_weight,
      mission_type_weight: state.mission_type_weight,
      item_category_weight: state.item_category_weight,
      reward_weight: state.reward_weight,
      is_precheck_weight: state.is_precheck_weight,
    }).then(response => {
      state.campaignList = response.data.data
    }).catch(err => {
      turnOnErrorModal(err)
    }).finally(() => {
      state.isSaving = false
    })
  }
  return {
    recommend,
  }
}
