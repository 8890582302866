<template>
  <TagbyTable
    :items="state.campaignList"
    :fields="fields"
    :busy="state.isSaving"
  >
    <template #cell(idx)="data">
      <ComptIdxColumn :campaignIdx="data.value" />
    </template>

    <template #cell(similarity)="data">
      {{ data.value.toFixed(2) }}
    </template>
  </TagbyTable>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import TagbyTable from "@/components/TagbyTable.vue"
import state from "../../state"
import ComptIdxColumn from "@/components/ComptIdxColumn.vue"

export default defineComponent({
  components: {
    TagbyTable,
    ComptIdxColumn,
  },
  setup() {
    const fields = [
      "idx", "title", "similarity",
    ]

    return {
      state,
      fields,
    }
  },
})
</script>
