<template>
  <BCard>
    <BRow>
      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="인플루언서 IDX">
          <BFormInput
            v-model="state.inf_idx"
            number
            type="number"
          />
        </BFormGroup>
      </BCol>

      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="성별 가중치">
          <BFormInput
            v-model="state.gender_weight"
            number
            type="number"
          />
        </BFormGroup>
      </BCol>

      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="나이대 가중치">
          <BFormInput
            v-model="state.age_weight"
            number
            type="number"
          />
        </BFormGroup>
      </BCol>

      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="미션타입 가중치">
          <BFormInput
            v-model="state.mission_type_weight"
            number
            type="number"
          />
        </BFormGroup>
      </BCol>

      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="카테고리 가중치">
          <BFormInput
            v-model="state.item_category_weight"
            number
            type="number"
          />
        </BFormGroup>
      </BCol>

      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="리워드 가중치">
          <BFormInput
            v-model="state.reward_weight"
            number
            type="number"
          />
        </BFormGroup>
      </BCol>

      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="원고여부 가중치">
          <BFormInput
            v-model="state.is_precheck_weight"
            number
            type="number"
          />
        </BFormGroup>
      </BCol>

    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BFormCheckbox,
  BFormTags,
  BFormTextarea,
  BFormTimepicker,
  BFormSelect,
} from "bootstrap-vue"
import TagbyDatetimeInputV3 from "@/components/TagbyDatetimeInputV3.vue"
import state from "../../state"
import getters from "../../getters"
import TagbySelect from "@/components/TagbySelect.vue"

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    TagbyDatetimeInputV3,
    TagbySelect,
    BFormCheckbox,
    BFormTags,
    BFormTextarea,
    BFormTimepicker,
    BFormSelect,
  },
  setup() {
    const stateOptions = [
      "ACTIVE",
      "STOP",
      "INACTIVE",
    ]
    const test = value => {
      console.log(value)
    }
    const getFormState = value => {
      if (value == null || value === "") {
        return false
      }
      if (value instanceof Array && value.length === 0) {
        return false
      }
      return null
    }
    return {
      state,
      getters,
      stateOptions,
      test,
      getFormState,
    }
  },
})
</script>
